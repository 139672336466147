import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import formStyles from '../../styles/form.module.css';

const PressPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					epk,
					password
				},
				html: body
			}
		}
	} = data;

	const [ pass, setPass ] = useState( '' );
	const [ confirmed, setConfirmed ] = useState( false );
	const [ denied, setDenied ] = useState( false );

	const handleChange = useCallback( e => {
		setPass( e.target.value );
	}, [] );

	const handleConfirm = useCallback( () => {
		if ( pass === password ) {
			setConfirmed( true );
			setDenied( false );
		} else {
			setPass( '' );
			setDenied( true );
		}
	}, [ pass, password ] );

	return (
		<>
			<section>
				<div className="wrapper">
					{ body ? <div className="body" dangerouslySetInnerHTML={ { __html: body } } /> : null }
					{ !confirmed
						? <>
							<div className={ formStyles.form } style={ { width: '400px' } }>
								<div className={ formStyles.formContainer }>
									<span className={ formStyles.inputs }>
										<div className={ formStyles.field }>
											<label htmlFor="password">{ denied ? <span className={ formStyles.error }>Incorrect Password. Please <Link to="/contact">request access here</Link></span> : 'Password' }</label>
											<input
												id="password"
												type="text"
												name="password"
												value={ pass }
												onChange={ handleChange }
											/>
										</div>
									</span>
								</div>
								<button
									className={ formStyles.submit }
									disabled={ !pass.length }
									onClick={ handleConfirm }
								>
									Confirm
								</button>
							</div>
						</>
						: <div className="body">
							<br />
							<p>
								<a download href={ epk }>Download EPK</a>
							</p>
						</div>
					}
				</div>
			</section>
		</>
	);
};

PressPage.propTypes = { data: PropTypes.object };

export default PressPage;

export const query = graphql`
	query PressPage {
		file(relativePath: {eq: "pages/press.md"}) {
			childMarkdownRemark {
				frontmatter {
					epk
					password
				}
				html
			}
		}
	}
`;
